.component{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width:60%;
	padding: 100px 20px;
}

.componentTitle{
	text-align: center;
	font-size: 60px;
}

.componentSubTitle{
	text-align: center;
	font-size: 40px;
	padding: 30px 0px 30px 40px;
}
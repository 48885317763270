.projectInfo{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:100%;
  min-height: 90%;
  color: black;
}

.projectName{
  text-align: center;
  font-size: 30px;
  color: black;
}

.media{
  max-width:700px;
}

.image{
  width:800px;
  height:480px;
}

.text{
  width:800px;
}
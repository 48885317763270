.contact{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width:60%;
	padding: 100px 20px;
}

.contactTitle{
	text-align: center;
	font-size: 60px;
	padding: 0px 0px 30px 0px;
}

.imageLink {
	width:128px;
	height:128px;
}
.video-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 0;
  padding: 56.25% 0 0 0; /* 100%/16*9 = 56.25% = Aspect ratio 16:9 */
  overflow: hidden;
  border: 0;
}

.video-wrapper video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
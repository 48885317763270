.projects{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width:60%;
	padding: 100px 20px;
}

.projectsTitle{
	padding: 30px 20px;
	text-align: center;
	font-size: 60px;
}

.smallerProjectsTitle{
	padding: 30px 20px;
	text-align: center;
	font-size: 40px;
	margin-top:10px;
}

.smallProjectsContainer{
	text-align: center;
}

ul.languageUL {
  display: inline-block;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.languages {
    text-align: center;
    font-size: 30px;
    font-weight: bold;

    padding: 30px 0px 30px 40px;
}

li.languageUL{
	margin-top:10px;
}